import './style.css'
const ButtonMenu = ({clickMenu, menu, classna}) => {
  
  if (menu==false){
    menu = <input type="checkbox" />
  }else{
    menu = <input type="checkbox" checked/>
  }
  return (
    <div id="menuToggle" className={classna} onClick={clickMenu}>
      {menu}
      <span></span>
      <span></span>
      <span></span>
      <ul id="menu">
      </ul>
    </div>
  )
}

export {ButtonMenu}
