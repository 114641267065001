import './style.scss';
import {NavLink, Link} from 'react-router-dom'
import logo from '../../navbar/images/logo.svg'
import { useState, useEffect } from 'react'
import { ButtonMenu } from '../../buttonmenu/ButtonMenu'
import { CSSTransition } from 'react-transition-group'

const AdmNavbar = () => {
  
  const[menu, setMenu] = useState(false)
  
  const[windowSize, setWindowSize] = useState([
    window.innerWidth
  ]);
  
  // Вычисляем ширину сайта
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth])
      
    };
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    }, []);
  
  // Если <= 800 то выключаем меню  
  useEffect(() => { 
    if (windowSize[0] <= 800){
      setMenu(false)
    }
    else{
      setMenu(true)
    }
  },[windowSize[0]])
  
  // Кнопка всплывания меню
  const clickMenu = () => {
    setMenu(!menu)
  }
  
  // Назваине класса для ширины появления мобильного меню
  const classna = 'admnavbar xx';
    
  return (
    <>
      <div className="admin_header">
          <div className="admin_header_container">
              <Link to="/" className="logo" id="logo"><img src={logo} /> </Link>
              
          <CSSTransition in={menu} timeout={300}
          classNames='alert' unmountOnExit>
              <ul className="admin_menu">
                  <li><NavLink to="/adm/new_article">новая <br/>статья</NavLink></li>
                  <li><NavLink to="/adm/new_category">новая <br/>категория</NavLink></li>
                  <li><NavLink to="/adm/update">редактировать/<br/>удалить статью</NavLink></li>
                  <li><NavLink to="/adm/new_tag">создать <br/>тег</NavLink></li>
                  <li><NavLink to="/adm/new_partner">новый <br/>партнер</NavLink></li>
                  <li><NavLink to="/adm/new_school">новая <br/>школа</NavLink></li>
              </ul>
          </CSSTransition>
    
              <ButtonMenu clickMenu={clickMenu} set={setMenu, menu} classna={classna} />
        </div>
      </div>

      <div className="menu_cases">
          <NavLink to="/adm/new_job">Создать рабочую рубрику</NavLink>
          <NavLink to="/adm/new_enterprises">Создать предприятие</NavLink>
          <NavLink to="/adm/new_vacancies">Создать вакансию</NavLink>
          <NavLink to="/adm/update_vacancies">Редактировать вакансию</NavLink>
          <NavLink to="/adm/banner">Баннер</NavLink>
          <NavLink to="/adm/reload_site_map">Sitemap</NavLink>
      </div>
    </>
  )
}

export {AdmNavbar}
