import { RouterProvider, createBrowserRouter, Link } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";

//Layout
import {IndexLayout} from '../components/layout/IndexLayout'
import {AdmLayout} from '../components/layout/AdmLayout'

//Index
// import { Indexpage } from '../pages/Indexpage';
// import { News } from '../pages/News'
// import { OneNew } from '../pages/OneNew'
import { Notfoundpage } from '../pages/Notfoundpage'

//Admin
// import { NewArticle } from '../pages/admin/NewArticle'
// import { NewCategory } from '../pages/admin/NewCategory.jsx'
// import { NewTag } from '../pages/admin/NewTag.jsx'


import  Login  from '../pages/Login'
import  Logout  from '../pages/Logout'

// const Indexpage = lazy(() => import('../pages/Indexpage'));
// import { Indexpage } from '../pages/Indexpage'


const Routes = () => {
  const { token } = useAuth();

  // Маршруты доступные для всех пользователей
  const routesForPublic = [
    {
      path: "/",
      element: <IndexLayout />,
      children: [
        {
          path: "/",
          // element: <Indexpage />
          lazy: async () => {let { Indexpage } = await import("../pages/Indexpage")
            return {Component: Indexpage}
          }
          // async lazy() {
          //   let{ Indexpage } = await import("../pages/Indexpage");
          //   return {Component: Indexpage};
          // },
        },
        {
          path: "*",
          element: <Notfoundpage />
        },
        {
          path: "news",
          lazy: async () => {let { News } = await import("../pages/News")
            return {Component: News}
          }
        },
        {
          path: "news/:category",
          lazy: async () => {let { CategoryArticle } = await import("../pages/CategoryArticle")
            return {Component: CategoryArticle}
          }
        },
        {
          path: "news/:category/:url/:id",
          lazy: async () => {let { OneNew } = await import("../pages/OneNew")
            return {Component: OneNew}
          }
        },
        {
          path: "activities/:url_tag/",
          lazy: async () => {let { Activities } = await import("../pages/Activities")
            return {Component: Activities}
          }
        },
        {
          path: "about_us/:url/:id",
          lazy: async () => {let { AboutUs } = await import("../pages/AboutUs")
            return {Component: AboutUs}
          }
        },
        {
          path: "partners",
          lazy: async () => {let { Partners } = await import("../pages/Partners")
            return {Component: Partners}
          }
        },
        {
          path: "school",
          lazy: async () => {let { School } = await import("../pages/School")
            return {Component: School}
          }
        },
        {
          path: "vacancies/:enterprises",
          lazy: async () => {let { Vacancies } = await import("../pages/Vacancies")
            return {Component: Vacancies}
          }
        },
        {
          path: "vacancies/:enterprises/:job",
          lazy: async () => {let { VacanciesJob } = await import("../pages/VacanciesJob")
            return {Component: VacanciesJob}
          }
        },
        {
          path: "vacancy/:enterprises/:vac",
          lazy: async () => {let { Vacancy_one } = await import("../pages/Vacancy_one")
            return {Component: Vacancy_one}
          }
        },
        {
          path: "search",
          lazy: async () => {let { Search } = await import("../pages/Search")
            return {Component: Search}
          }
        },
        
      ]
    },
    
    {
      path: "/service",
      element: <div>Service Page</div>,
    },
    
    {
      path: "/about",
      element: <div>About Us</div>,
    },
    
  ];




  // Маршруты доступные только для аутентифицированных пользователей
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [

        {
          path: "/adm",
          element: <AdmLayout />,
          children: [
            {
              path: "new_article",
              lazy: async () => {let { NewArticle } = await import("../pages/admin/NewArticle")
                return {Component: NewArticle}
              }
            },
            {
              path: "new_category",
              lazy: async () => {let { NewCategory } = await import("../pages/admin/NewCategory")
                return {Component: NewCategory}
              }
            },
            {
              path: "new_tag",
              lazy: async () => {let { NewTag } = await import("../pages/admin/NewTag")
                return {Component: NewTag}
              }
            },
            {
              path: "new_job",
              lazy: async () => {let { NewJob } = await import("../pages/admin/NewJob")
                return {Component: NewJob}
              }
            },
            {
              path: "new_enterprises",
              lazy: async () => {let { NewEnterprises } = await import("../pages/admin/NewEnterprises")
                return {Component: NewEnterprises}
              }
            },
            {
              path: "new_vacancies",
              lazy: async () => {let { NewVacancies } = await import("../pages/admin/NewVacancies")
                return {Component: NewVacancies}
              }
            },
            {
              path: "article_done",
              lazy: async () => {let { Article } = await import("../pages/admin/Article")
                return {Component: Article}
              }
            },
            {
              path: "vacancy_done",
              lazy: async () => {let { Vacancy } = await import("../pages/admin/Vacancy")
                return {Component: Vacancy}
              }
            },
            {
              path: "banner_done",
              lazy: async () => {let { BannerDone } = await import("../pages/admin/BannerDone")
                return {Component: BannerDone}
              }
            },
            {
              path: "update",
              lazy: async () => {let { Update } = await import("../pages/admin/Update")
                return {Component: Update}
              }
            },
            {
              path: "update_vacancies",
              lazy: async () => {let { UpdateVacancies } = await import("../pages/admin/UpdateVacancies")
                return {Component: UpdateVacancies}
              }
            },
            {
              path: "new_partner",
              lazy: async () => {let { NewPartner } = await import("../pages/admin/NewPartner")
                return {Component: NewPartner}
              }
            },
            {
              path: "new_school",
              lazy: async () => {let { NewSchool } = await import("../pages/admin/NewSchool")
                return {Component: NewSchool}
              }
            },
            {
              path: "update_post/:id",
              lazy: async () => {let { UpdatePost } = await import("../pages/admin/UpdatePost")
                return {Component: UpdatePost}
              }
            },
            {
              path: "update_vacancy/:id",
              lazy: async () => {let { UpdateVacancy } = await import("../pages/admin/UpdateVacancy")
                return {Component: UpdateVacancy}
              }
            },
            {
              path: "banner",
              lazy: async () => {let { Banner } = await import("../pages/admin/Banner")
                return {Component: Banner}
              }
            },
            {
              path: "reload_site_map",
              lazy: async () => {let { Sitemap } = await import("../pages/admin/Sitemap")
                return {Component: Sitemap}
              }
            }
          ]
          
          
        },
        

        {
          path: "/profile",
          element: <div>Профиль</div>,
        },

        {
          path: "/logout",
          element: <Logout />,
        },
      ],
    },
  ];




  // Маршруты доступные только неавторизированным пользователям
  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <div>Home Page</div>,
    },
    {
      path: "/login",
      element: <Login />,
    },
  ];





  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} fallbackElement={<p>Loading.....</p>}/>;
};

export default Routes;

