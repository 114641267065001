import './style.css'
import logo from './images/logo-white.svg'
import kunica from './images/kunica.svg'
import telegram from './images/telegram.svg'
import vk from './images/vk.svg'
import youtube from './images/youtube.svg'


const Footer = () => {


  return (
    <div className="footer">
      <div className="footer_container">
        <img src={logo} alt="logo" className="logo_footer" />
        <div className="footer_content">
          <div className="footer_head">Общероссийская общественная организация «Союз машиностроителей России Башкортостанское региональное отделение»</div>
          <div className="contacts">
            <div className="footer_address">
              <p>Адрес</p>
              <div className="ufa">450030, г. Уфа,<br /> ул. Ферина, 2</div>
            </div>
            <div className="contact">
              <p>Контакты</p>
              <div className="phone_footer"><a href="tel:+7(347) 238-09-54">+7(347)238-09-54</a></div>
              <div className="mail_footer"><a href="mailto:souzmash102mail.ru">souzmash102@mail.ru</a></div>
            </div>
            <div className="press">
              <p>Пресс-служба</p>
              <div className="phone_footer"><a href="tel:+7(347) 238-09-54">+7(347)238-09-54</a></div>
              <div className="mail_footer"><a href="mailto:souzmash102mail.ru">souzmash102@mail.ru</a></div>
            </div>
          </div>
          <div className="logo_social">
            <img src={kunica} alt="kunica" className="ku" />
            <p>2007-2023 Союз машиностроителей России. Башкортостанское региональное отделение</p>
            <div className="footer_social">
              <a href="https://t.me/broSMR02" target="_blank"><img src={telegram} alt="telegram" /></a>
              <a href="https://vk.com/smr02" target="_blank"><img src={vk} alt="vk" /></a>
              <a href="https://www.youtube.com/channel/UCVKK7YcXfhZi9QVgXyRxnww" target="_blank"><img src={youtube} alt="youtube" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer
