import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import {useNavigate} from 'react-router-dom'

const PopUpSearch = ({pop_del, clickSearch}) => {
  
  const navigate = useNavigate(); // навигация на другие страницы
  
  const [formNote, setFormNote] = useState({
      se: "",
    })

  function createNote() {

        navigate('/search/', {state:formNote})
        clickSearch()
    }

  function handleChange(event) { 
        const {value, name} = event.target
        setFormNote(prevNote => ({
            ...prevNote, [name]: value})
        )}
  

  
  return (
    
    <CSSTransition in={pop_del} timeout={300}
    classNames='alert' unmountOnExit>
    
    <div className="bg_search" >
      <div className="pop_up_search">

        <svg style={{cursor:"pointer"}} className="close_search" onClick={clickSearch} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
          <path d="M19.5563 17.5L25.8271 11.2437C26.1017 10.9691 26.256 10.5967 26.256 10.2083C26.256 9.81996 26.1017 9.44751 25.8271 9.1729C25.5525 8.89829 25.1801 8.74402 24.7917 8.74402C24.4033 8.74402 24.0309 8.89829 23.7563 9.1729L17.5 15.4437L11.2438 9.1729C10.9692 8.89829 10.5967 8.74402 10.2084 8.74402C9.82001 8.74402 9.44756 8.89829 9.17295 9.1729C8.89834 9.44751 8.74407 9.81996 8.74407 10.2083C8.74407 10.5967 8.89834 10.9691 9.17295 11.2437L15.4438 17.5L9.17295 23.7562C9.03626 23.8918 8.92777 24.0531 8.85373 24.2308C8.7797 24.4085 8.74158 24.5991 8.74158 24.7917C8.74158 24.9842 8.7797 25.1748 8.85373 25.3525C8.92777 25.5302 9.03626 25.6915 9.17295 25.8271C9.30852 25.9638 9.46981 26.0722 9.64753 26.1463C9.82524 26.2203 10.0158 26.2584 10.2084 26.2584C10.4009 26.2584 10.5915 26.2203 10.7692 26.1463C10.9469 26.0722 11.1082 25.9638 11.2438 25.8271L17.5 19.5562L23.7563 25.8271C23.8919 25.9638 24.0531 26.0722 24.2309 26.1463C24.4086 26.2203 24.5992 26.2584 24.7917 26.2584C24.9842 26.2584 25.1748 26.2203 25.3525 26.1463C25.5303 26.0722 25.6915 25.9638 25.8271 25.8271C25.9638 25.6915 26.0723 25.5302 26.1463 25.3525C26.2204 25.1748 26.2585 24.9842 26.2585 24.7917C26.2585 24.5991 26.2204 24.4085 26.1463 24.2308C26.0723 24.0531 25.9638 23.8918 25.8271 23.7562L19.5563 17.5Z" fill="#B1B1B1"/>
        </svg>
    
        <form className="form_search">
          <input onChange={handleChange} className="input_search" type="text" name="se" maxLength="300" value={formNote.search} placeholder="Введите запрос" required=""/>
          <input onClick={createNote} className="search_button"   value="найти"/>
        </form>

      </div>  
    </div>
    
    </CSSTransition>
    
  )
}

export {PopUpSearch}
