import {Outlet} from 'react-router-dom'
import Navbar from '../navbar/Navbar.js'
import Space from '../space/Space.js'
import Footer from '../footer/Footer'
import { useState } from 'react'
import {Suspense} from 'react'

const IndexLayout = () => {
  
  // Вытаскиваем из Navbar данные "Категории" и отправляем в Outlet
  const [content, setContent] = useState(0)
  const handlName = (content) => {
    setContent(content)
  }
  
  
  return (
    <>
      <Navbar onChange={handlName} />
      <Space />
        <Suspense fallback={<h1>Loading...</h1>}>
          <Outlet context={content}/>
        </Suspense>
      <Footer />
    </>
  )
}

export {IndexLayout}
