import './style.css'


const Space = () => {
  
  
  
  return (
    <div className="space">
    
    </div>
  );
}

export default Space
