import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'

function Cat({id, title, url, clickNewsMenu, name_url}){



  return (
    <Link to={name_url+url} onClick={clickNewsMenu}>{title}</Link>
  )
}

export default Cat;
