import { useNavigate } from "react-router-dom";
import { useState } from 'react'
import { useAuth } from "../provider/authProvider";
import axios from "axios";
import '../components/layout/style.scss'

const Login = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [formNote, setFormNote] = useState({
    username: "",
    password: "",
  })

  
  const handleLogin = (event) => {
    axios
      .post("api/re/token/", {
        username: formNote.username,
        password: formNote.password,
      }).then((res) => {
        const token = res.data;
        console.log(token)
        setToken(token);
        navigate("/adm/new_article", { replace: true });
      })
    
    // setToken("this is a test token");
    // navigate("/", { replace: true });
    event.preventDefault()
  };

  setTimeout(() => {
    // handleLogin();
  }, 3 * 1000);
  
  function handleChange(event) {
    const {value, name} = event.target
    setFormNote(prevNote => ({
      ...prevNote, [name]: value})
    )}

  return (
      <div className="container_admin_form">
        <h3>Введите логин и пароль</h3>
        <form method="post">
          <div className="column_one_res">
            <input onChange={handleChange} text={formNote.username} name="username" type="text"  className="admin_name" maxLength="200" placeholder="Введите логин" value={formNote.username} required />
            <input onChange={handleChange} text={formNote.password} name="password" type="password"  className="admin_name" maxLength="200" placeholder="Введите пароль" value={formNote.password} required  />
          </div>
          <button onClick={handleLogin} className="admin_button_cat">Отправить</button>
        </form>
      </div>
  )
};

export default Login;
