import './style.scss'
import './script.js'
import logo from './images/logo.svg'
import kunica from './images/kunica.svg'
import telegram from './images/telegram.svg'
import vk from './images/vk.svg'
import youtube from './images/youtube.svg'
import { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import axios from "axios";
import Cat from './Cat'

import { NavLink, Link }  from 'react-router-dom';
import { ButtonMenu } from '../buttonmenu/ButtonMenu'
import { PopUpSearch } from '../pop_up_search/PopUpSearch'


import { lazy, Suspense } from "react"
const New = lazy(() => import('../menu/new/new'));
const Event = lazy(() => import('../menu/event/Event'));
const Souz = lazy(() => import('../menu/souz/souz'));
const Vacancies = lazy(() => import('../menu/vacancies/vacancies'));
// const Tsok = lazy(() => import('../menu/tsok/tsok'));
const Project = lazy(() => import('../menu/project/project'));


// Открыто закрыто меню      
const Navbar = ({onChange}) => {
  
  const[menu, setMenu] = useState(false)
  const[menunews, setNewsMenu] = useState(false)
  const[menuevent, setMenuEvent] = useState(false)
  const[menusouz, setMenuSouz] = useState(false)
  const[menuproject, setMenuProject] = useState(false)
  // const[menutsok, setMenuTsok] = useState(false)
  const[menuvacancies, setMenuVacancies] = useState(false)
  
  // Внутри меню, категории и тд.
  const [categories, setCategory] = useState(null)
  
  
  const[windowSize, setWindowSize] = useState([
    window.innerWidth
  ]);

  useEffect(() => {
    getCategory();
      } , [])
 
  
  // Получаем список категорий
  function getCategory() {
    axios({
      method: "GET",
      url:"/api/category/",
    }).then((response)=>{
      const data = response.data
      setCategory(data)
      onChange(data) // Отправляем данные в IndexLayout
    }).catch((error) => {
      if (error.response) {
      console.log(error.response);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  })}
  
  
  
  // Вычисляем ширину сайта
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth])
    };
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    }, []);
  
  

  
  const[nnews, setNnews] = useState('')
  
  // Если <= 660 то выключаем меню  
  useEffect(() => { 
    if (windowSize[0] <= 660){
      setMenu(false)
      setNnews(<a style={{cursor:"pointer"}} >Новости</a>)
    }
    else{
      setMenu(true)
      setNnews(<NavLink to="/news" >новости</NavLink>)
     
    }
  },[windowSize[0]])
  
  
  
  // Кнопка всплывания меню
  const clickMenu = () => {
    if(windowSize[0] <= 660){
      setMenu(!menu)
    }
  }

  // Кнопка всплытия меню "Новости"
  const clickNewsMenu = () => {
    setNewsMenu(!menunews)
  }
  
  // Кнопка всплытия меню "Мероприятия"
  const clickMenuEvent = () => {
    setMenuEvent(!menuevent)
  }
  
  // Кнопка всплытия меню "О союзе"
  const clickMenuSouz = () => {
    setMenuSouz(!menusouz)
  }
  
  // Кнопка всплытия меню "Проект"
  const clickMenuProject = () => {
    setMenuProject(!menuproject)
  }
  
  // Кнопка всплытия меню "ЦОК"
  // const clickMenuTsok = () => {
  //   setMenuTsok(!menutsok)
  // }
  
  // Кнопка всплытия меню "Вакансии"
  const clickMenuVacancies = () => {
    setMenuVacancies(!menuvacancies)
  }
  
  // Назваине класса для ширины появления мобильного меню
  const classna = 'navbar xx';
  
  const [pop_del, setPopDel] = useState(false)
  
  const clickSearch = () => {
    setPopDel(!pop_del)
  }
  
  
  
  return (
    
    <nav className="nav">


     <PopUpSearch  pop_del={pop_del} clickSearch={clickSearch}/>

    
      <div className="container">
        <Link to="/"><img src={logo} alt="logo" className="logo" id="logo"/></Link>
        <div className="content" >
          <div className="cont">
            <div className="address">
              <p>450030, г.Уфа Ферина, 2</p>
    
              <ButtonMenu clickMenu={clickMenu} menu={menu} classna={classna} />
    
            </div>
            <div className="search_social_phone">
              <div style={{cursor:"pointer"}} onClick={clickSearch} className="search">поиск</div>
              <div className="social">
                <a href="https://t.me/broSMR02" target="_blank"><img src={telegram} alt="телеграм" /></a>
                <a href="https://vk.com/smr02" target="_blank"><img src={vk} alt="вконтакте" /></a>
                <a href="https://www.youtube.com/channel/UCVKK7YcXfhZi9QVgXyRxnww" target="_blank"><img src={youtube} alt="youtube" /></a>
              </div>
              <a href="tel:+7(347) 238-09-54"><p>+7(347)238-09-54</p></a>
            </div>
          </div>
    

    
          <CSSTransition in={menu} timeout={300}
          classNames='alert' unmountOnExit>
            <ul className="menu">

    
              <li><NavLink to="/" onClick={clickMenu} >главная</NavLink></li>
    
    
              <li className="re"  onMouseEnter={clickNewsMenu} onMouseLeave={clickNewsMenu}>
                  {nnews}
                  <Suspense fallback="Load">
                    {menunews && <New  menunews={menunews} categories={categories} setNewsMenu={setNewsMenu} setMenu={setMenu} menu={menu} windowSize={windowSize} />}
                  </Suspense>
              </li>
    
    
              <li className="re" onMouseEnter={clickMenuEvent} onMouseLeave={clickMenuEvent}>
                <a style={{cursor:"pointer"}} >мероприятия</a>
                  <Suspense fallback="Load">
                    {menuevent && <Event  menuevent={menuevent} setMenuEvent={setMenuEvent} setMenu={setMenu} menu={menu} windowSize={windowSize}/>}
                  </Suspense>
              </li>
    
    
              <li className="re" onMouseEnter={clickMenuSouz} onMouseLeave={clickMenuSouz} >
                <a style={{cursor:"pointer"}}>О союзе</a>
                  <Suspense fallback="Load">
                    {menusouz && <Souz  menusouz={menusouz} setMenuSouz={setMenuSouz} setMenu={setMenu} menu={menu} windowSize={windowSize}/>}
                  </Suspense>
              </li>
    
    
              <li><NavLink to="/about_us/smi-o-nas/" onClick={clickMenu}>сми о нас</NavLink></li>
    
    
              <li className="re" onMouseEnter={clickMenuVacancies} onMouseLeave={clickMenuVacancies}>
                <a style={{cursor:"pointer"}}>вакансии</a>
                  <Suspense fallback="Load">
                    {menuvacancies && <Vacancies menuvacancies={menuvacancies} setMenuVacancies={setMenuVacancies} setMenu={setMenu} menu={menu} windowSize={windowSize}/>}
                  </Suspense>
              </li>
    
    
              <li><NavLink to="/about_us/tsentr-otsenki-kvalifikatsii-tsok/5" onClick={clickMenu}>цок</NavLink></li>
  
          {/* открывающее меню 
          <li className="re right" onMouseEnter={clickMenuTsok} onMouseLeave={clickMenuTsok}>
                <a style={{cursor:"pointer"}}>цок</a>
                  <Suspense fallback="Load">
                    {menutsok && <Tsok menutsok={menutsok} setMenuTsok={setMenuTsok} setMenu={setMenu} menu={menu} windowSize={windowSize}/>}
                  </Suspense>
              </li> */}
    
    
              <li className="re right" onMouseEnter={clickMenuProject} onMouseLeave={clickMenuProject}>
                <a style={{cursor:"pointer"}}>проекты</a>
                  <Suspense fallback="Load">
                    {menuproject && <Project menuproject={menuproject} setMenuProject={setMenuProject} setMenu={setMenu} menu={menu} windowSize={windowSize}/>}
                  </Suspense>
              </li>
    
            </ul>
          </CSSTransition>

    
        </div>
      </div>
    </nav>
  );
}

export default Navbar
