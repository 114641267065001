const Notfoundpage = () => {
  
  return (
      <>
        <div>Ошибка 404 - ничего не найдено</div>
      </>
  );
}

export {Notfoundpage};
